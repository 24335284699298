import { useState } from 'react';
import { toast } from 'react-toastify';
import Helper from '../../../common/consts/Helper';
import ViewModal from './OppositionModal';

export default function Opposition(props) {

    const [services, setServices] = useState(props?.location?.state?.service?.services)
    const [data, setData] = useState([]);

    function processDate(date) {
        let processedDate = new Date(date);
        return (`${processedDate.getDate()}-${processedDate.getMonth() + 1}-${processedDate.getFullYear()}`)
    }

    function setStatus(e, id, index) {
        setServiceState(e, index)
        let obj = {
            serviceId: id,
            serviceStatus: parseInt(e.target.value)
        }
        Helper('changeStatus', 'POST', obj, false)
            .then(response => {
                toast.success(response.msg)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function setServiceState(e, index) {
        let serviceArr = services;
        serviceArr[index].serviceStatus = parseInt(e.target.value);
        setServices([...serviceArr])
    }


    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                        </th>
                                        <th className="text-center">
                                            Order Id
                                        </th>
                                        <th className="text-center">
                                            Email
                                        </th>
                                        <th className="text-center">
                                            Mobile
                                        </th>
                                        <th className="text-center">
                                            Request Date
                                        </th>
                                        <th className="text-center">
                                            Amount
                                        </th>
                                        <th className="text-center">
                                            Payment
                                        </th>
                                        <th className="text-center">
                                            Options
                                        </th>
                                        <th className="text-center">
                                            Status
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            services.map((service, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">
                                                        {service?.payment?.length > 0 ? service?.payment[service?.payment?.length - 1]?.id : "-"}
                                                    </td>
                                                    <td className="text-center">{service?.userDetails?.email}</td>
                                                    <td className="text-center">{service?.userDetails?.mobile}</td>
                                                    <td className="text-center">{processDate(service?.createdAt)}</td>
                                                    <td className="text-center text-bold">
                                                        {
                                                            service?.payment?.length > 0 ? parseFloat(service?.payment[service?.payment?.length - 1]?.amount / 100).toFixed(2) : "-"
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {service?.payment?.length > 0 ? service?.payment[service?.payment?.length - 1]?.captured ? <p className="text-primary">Success</p> : <p className="text-danger">Failed</p> : "-"}
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" data-toggle="modal" data-target="#recordalModal" className="btn btn-primary" onClick={() => setData(service?.recordalChange)}>View</button>
                                                    </td>
                                                    <td className="text-center">
                                                        <select className="form-control" onChange={(e) => setStatus(e, service?._id, index)} value={service?.serviceStatus}>
                                                            <option value={0}>Pending</option>
                                                            <option value={1}>Processing</option>
                                                            <option value={2}>Done</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewModal data={data} />
        </div>
    )
}
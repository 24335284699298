import { Input } from 'antd';
import Helper from '../../common/consts/Helper'
import { useEffect, useState } from 'react'
const { Search } = Input;
// import Spinner from '../../common/components/Spinner'

let allServicesNames = []

export default function AllServices() {
  const [userServiceList, setUserServiceList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userServiceListCopy, setUserServiceListCopy] = useState([])
  // used to set active tab
  const [isActive, setIsActive] = useState('')


  useEffect(() => {
    setIsLoading(true)
    Helper('servicesAdmin',"POST",'',false)
    .then((res)=>{
      setUserServiceList(res.services)
      setUserServiceListCopy(res?.services)
      if(res?.services?.length > 0){
        setAllServicesData(res?.services)
      }
    })
    .catch((err)=>console.log(err,"fsadf"))
    .finally(()=>{
      setIsLoading(false)
    })

  }, [])


  // used to set userServiceList variable data  acc to tab hit on left side in webView
  function changeService(serviceName) {
    let filter = [];
    userServiceListCopy.map((service, indx) => {
      if (serviceName === service.serviceName) {
        filter.push(service)
      }
    })
    setUserServiceList(filter)
    setIsActive(serviceName)
  }

  return (
    <>

      <div className="content">
        <div className="row">
          <div className="col " style={{ fontSize: "30px" }}>
            <div className="card">
              <div className="card-body text-center">
                My DashBoard
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-5">
            {
              allServicesNames?.length > 0 ? (
                allServicesNames?.map((name, indx) =>
                  <div
                    key={indx}
                    className={isActive === name ? "isActiveServiceBox d-flex align-items-center justify-content-center my-4 px-4" : "serviceBox d-flex align-items-center justify-content-center my-4 px-4"}
                    style={{ cursor: "pointer" }}
                    onClick={() => changeService(name)}
                  >
                    {name}
                  </div>
                )
              ) : null
            }


          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className=" text-primary">
                      <th className="text-center">
                        Sno
                                        </th>
                      <th className="text-center">
                        Service Name
                                        </th>

                      <th className="text-center">
                        User Email
                                        </th>
                      <th className="text-center">
                        User Contact
                                        </th>
                      <th className="text-center">
                        <span className=" d-flex align-items-center justify-content-center">
                          Enquiry
                                        </span>
                      </th>
                      <th className="text-center">
                        <span className=" d-flex align-items-center justify-content-center">
                          Payment(INR)
                                        </span>
                      </th>
                      <th className="text-center">
                        Status
                                        </th>
                      <th className="text-center">
                        View Details
                                        </th>
                    </thead>
                    <tbody>
                      {
                        userServiceList?.length > 0 ? (
                          userServiceList?.map((service, indx) =>
                            <tr key={service._id}>
                              <td className="text-center">{indx + 1}</td>
                              <td className="text-center">{service?.serviceName}</td>
                              <td className="text-center">{service?.userDetails?.email}</td>
                              <td className="text-center">{service?.userDetails?.mobile}</td>
                              <td className="text-center">
                                {service.isPayment === 1 ? "No" : "Yes"}
                              </td>
                              <td className="text-center">
                                {service?.isPayment === 1 ? (
                                  <>
                                    {(+service?.govtFees + +service?.professionalFee) + ((+service?.govtFees + +service?.professionalFee) * 14 / 100)}
                                  </>
                                ) : "_"}

                              </td>
                              <td className="text-center">
                                <select class="custom-select">
                                  <option value="Accept">
                                    {service.serviceStatus === 1 ? "Accept" : "Pending"}
                                  </option>
                                  <option value="Pending">
                                    {service.serviceStatus === 0 ? "Accept" : "Pending"}
                                  </option>
                                </select>
                              </td>
                            </tr>
                          )
                        ) : <tr className="text-center">No service Found</tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  function setAllServicesData(allServices) {

    allServices.map((service, indx) => {
      if (service.serviceName === "Trademark Registration") {
        // trademarkRegistrationTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "International Registration") {
        // internationalRegistrationTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Trademark Registration For Start-Ups") {
        // tradeForStartUpTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Registration Of Trademark With Indian Customs") {
        allServicesNames = [...allServicesNames, service.serviceName]
        // tradeIndianCustomTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Reply To Office Action") {
        // replyToOfficeTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Opposition Proceedings") {
        // oppositionProcedingTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Cancellation Proceedings") {
        // cancelProcedingTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Recordal Of Change Of Trademark Renewal") {
        // tradeRenewalTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Recordal Of Change Of Name") {
        // tradeNameChangeTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Recordal Of Address Change") {
        // tradeAddressChangeTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Recordal Of Change Of Proprietorship") {
        // changeOfProprietorshipTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Assignment Trademark") {
        // assignmentTradeTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]


      } else if (service.serviceName === "Licenses") {
        // licensesTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Cease & Desist Notices") {
        allServicesNames = [...allServicesNames, service.serviceName]

        // ceaseNoticeTemp.push(service)
      } else if (service.serviceName === "Trademark Litigation") {
        // tradeLitigationTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Anti-Counterfeit Practices") {
        // antiCounterTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]

      } else if (service.serviceName === "Preliminary Trademark Search") {
        // tradeSearchTemp.push(service)
        allServicesNames = [...allServicesNames, service.serviceName]
      }

    })

    allServicesNames = [...new Set(allServicesNames)];

    // used to set service first time
    let filter = [];
    allServices.map((service, indx) => {
      if (allServicesNames[0] === service.serviceName) {
        filter.push(service)
      }
    })
    setUserServiceList(filter)
    setIsActive(allServicesNames[0])
  }
}
import React, { useState, useEffect } from 'react';
import Helper from '../../common/consts/Helper';
import CountUp from 'react-countup';
import Spinner from '../../common/components/Spinner';
import Spinner2 from '../../common/components/Spinner2';
import { toast } from 'react-toastify';

export default function Dashboard() {

    const [stats, setStats] = useState({
        totalUsers: 0,
        totalServices: 0,
        totalRevenue: 0
    })
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [showUsers, setShowUsers] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        Helper('getStats', 'POST', {})
            .then(response => {
                setStats({
                    totalUsers: response.stats.users,
                    totalServices: response.stats.services,
                    totalRevenue: response.stats.revenue
                })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, []);

    useEffect(() => {
        if (showUsers) {
            setIsLoading2(true)
            Helper('getAllUsers', 'POST', {})
                .then(response => {
                    setUsers(response.users)
                })
                .catch((error) => {
                    toast.error(error)
                })
                .finally(() => {
                    setIsLoading2(false)
                })
        }
    }, [showUsers])
    const { totalUsers, totalServices, totalRevenue } = stats;

    function processDate(date) {
        let processedDate = new Date(date);
        return (`${processedDate.getDate()}-${processedDate.getMonth() + 1}-${processedDate.getFullYear()}`)
    }

    function changeCallingStatus(singleUser) {
        let localUserIndx = users?.findIndex((user, indx) => user?._id === singleUser._id)
        let updateUsers = users.slice()
        updateUsers[localUserIndx].isVisited = !updateUsers[localUserIndx]?.isVisited
        setUsers(updateUsers)
        let data = {
            userId: singleUser._id,
            isVisited: singleUser.isVisited
        }

        Helper('changeCheckedStatus', 'POST', data)
            .then(response => {
                console.log(response, "response")
                // setUsers(response.users)
            })
            .catch((error) => {
                toast.error(error)
            })
            .finally(() => {
                // setIsLoading2(false)
            })
        // }
    }

    return (
        <div className="content">
            {
                isLoading ? <Spinner2 /> :
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5 col-md-4">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-globe text-warning"></i>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-8">
                                            <div className="numbers" style={{ cursor: "pointer" }} onClick={() => setShowUsers(!showUsers)}>
                                                <p className="card-category">Total Users</p>
                                                <p className="card-title">
                                                    <CountUp end={totalUsers} start={0} duration={4} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i>
                                Updated Now
                            </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5 col-md-4">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-success"></i>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-8">
                                            <div className="numbers">
                                                <p className="card-category">Requests</p>
                                                <p className="card-title">
                                                    {/* <CountUp end={totalMagazines} start={0} duration={4} /> */}
                                                    <CountUp end={totalServices} start={0} duration={4} />

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar-o"></i>
                                Last day
                            </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5 col-md-4">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fas fa-money-check-alt text-danger"></i>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-8">
                                            <div className="numbers">
                                                <p className="card-category">Revenue</p>
                                                <p className="card-title">
                                                    {/* <CountUp end={totalProducts} start={0} duration={4} /> */}
                                                    <CountUp end={totalRevenue} start={0} duration={4} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-clock-o"></i>
                                In the last hour
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {
                showUsers ? (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            {/* <thead className=" text-primary"> */}
                                            <tbody>
                                                <tr className="text-primary">
                                                    <th className="text-center">
                                                        Sno
                                                    </th>
                                                    <th className="text-center">
                                                        Email
                                                    </th>
                                                    <th className="text-center">
                                                        Mobile
                                                    </th>
                                                    <th className="text-center">
                                                        Registration Date
                                                    </th>
                                                    <th className="text-center">
                                                        Calling
                                                    </th>
                                                </tr>
                                            </tbody>
                                            {/* </thead> */}
                                            <tbody>
                                                {
                                                    isLoading2 ? <Spinner /> :
                                                        users?.map((user, index) => (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{user.email}</td>
                                                                <td className="text-center">{user.mobile}</td>
                                                                <td className="text-center">{processDate(user?.createdAt)}</td>
                                                                <td className="text-center">
                                                                    <input type="checkbox" checked={user.isVisited} onClick={() => changeCallingStatus(user)} className="iscallingUsers" style={{ width: "23px", height: "23px" }} />

                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                                {/* {
                                                    services.map((service, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td className="text-center">
                                                                {service?.payment?.length > 0 ? service?.payment[service?.payment?.length - 1]?.id : "-"}
                                                            </td>
                                                            <td className="text-center">{service?.userDetails?.email}</td>
                                                            <td className="text-center">{service?.userDetails?.mobile}</td>
                                                            <td className="text-center">{service?.tradeName ? service?.tradeName : "-"}</td>
                                                            <td className="text-center">{service?.image ?
                                                                <a href={`${BASE_URL}${service?.image}`} target="_blank">
                                                                    <img className="applicationImg" src={`${BASE_URL}${service?.image}`} />
                                                                </a>
                                                                : "-"}
                                                            </td>
                                                            <td className="text-center">{processDate(service?.createdAt)}</td>
                                                            <td className="text-center">{service?.isPayment ? "Paid" : "Enquiry"}</td>
                                                            <td className="text-center text-bold">
                                                                {
                                                                    service?.payment?.length > 0 ? parseFloat(service?.payment[service?.payment?.length - 1]?.amount / 100).toFixed(2) : "-"
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {service?.payment?.length > 0 ? service?.payment[service?.payment?.length - 1]?.captured ? <p className="text-primary">Success</p> : <p className="text-danger">Failed</p> : "-"}
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#trademarkRegModal" className="btn btn-primary" onClick={() => setData(service)}>View</button>
                                                            </td>
                                                            <td className="text-center">
                                                                <select className="form-control" onChange={(e) => setStatus(e, service?._id, index)} value={service?.serviceStatus}>
                                                                    <option value={0}>Pending</option>
                                                                    <option value={1}>Processing</option>
                                                                    <option value={2}>Done</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )

    function fetchStats() {
        let res = Helper('/stats', 'GET');
        res.then((response) => {
            if (response.error) { }
            else {
                setStats({
                    ...response
                })
            }
        })
            .catch((error) => null)
    }

}

import Spinner from '../../common/components/Spinner';

export default function ChangePassword({handleChange, data, changePassword, isLoading, setChangeForm}) {
    return (
        <>
            <form onSubmit={changePassword}>
                <div className="form-group">
                    <label>OTP</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="OTP"
                        name="otp"
                        onChange={handleChange}
                        value={data.otp}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        value={data.password}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        value={data.confirmPassword}
                        required
                    />
                </div>
                <div className="row">
                    <button
                        type="submit"
                        className="btn btn-black"
                        disabled={isLoading}
                    >
                        {
                            isLoading ? <Spinner /> : 'Change Password'
                        }
                    </button>
                    <button
                        type="submit"
                        className="btn btn-black"
                        onClick={() => setChangeForm(1)}
                    >
                        Login
                    </button>
                </div>
            </form>
        </>
    )
}
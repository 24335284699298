import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Helper from "../../../common/consts/Helper";
import Editor from "./Editor";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

const inputFields = [
  { type: "text", name: "metaTitle", label: "Meta Title" },
  { type: "text", name: "metaDescription", label: "Meta Description" },
  { type: "text", name: "title", label: "Title" },
  { type: "textarea", name: "description", label: "Description" },
  { type: "text", name: "keywords", label: "Keywords" },
];
const intialState = {
  title: "",
  description: "",
  metaTitle: "",
  metaDescription: "",
  keywords: "",
  bannerImage: "",
};

const CreateBlogPage = () => {
  const [formData, setFormData] = useState(intialState);
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();

  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let bannerImage = "";
      if (file) {
        const data = new FormData();
        data.append("image", file);
        const response = await Helper("blogs/image", "POST", data, true);
        console.log(response, "respp");
        if (response?.success) {
          bannerImage = response?.fileName;
        }
      }

      const payload = formData;
      if (bannerImage.length) {
        payload.bannerImage = bannerImage;
      }

      if(!payload.bannerImage){
        return toast.error("Please upload banner image");
      }
      const response = await Helper("blogs", "POST", payload);
      if (response?.success) {
        if (formData?._id) {
          toast.success("updated successfully");
        } else {
          toast.success("blog added successfully");
          setFormData(intialState);
        }
      } else {
        console.log(response, "respppp");
        toast.error(response?.message || "internal server error");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  console.log(formData, "formmm");

  const getBlog = async (id) => {
    if (id) {
      const response = await Helper(`blogs/${id}`);
      console.log(response, "data");
      if (response.success) {
        setFormData(response.blog)
      }
    }
  };

  useEffect(() => {
    console.log(search, "sea");
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id");
    getBlog(id)
  }, []);

  return (
    <div className="content py-4">
      <h2 className="text-base font-semibold px-2 py-2">
        {formData?._id ? "Edit Blog" : "Add New Blog"}
      </h2>

      <div className="card py-4 px-4 gap-4">
        {/* <CreateBlog blog={blog} /> */}
        {inputFields.map((field, index) => (
          <div key={index} className="flex">
            {field.type === "textarea" ? (
              <div className="flex w-full flex-col gap-3 mb-4">
                <label
                  htmlFor={field.name}
                  className="text-base font-semibold text-black"
                >
                  {field.label}
                </label>
                <Editor
                  initialData={formData?.[field.name] ?? ""}
                  onChange={(value) =>
                    setFormData(() => ({ ...formData, description: value }))
                  }
                />
              </div>
            ) : (
              <div className="flex w-full flex-col gap-2 w-full">
                <label htmlFor={field.name} className="text-base font-semibold">
                  {field.label}
                </label>
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  placeholder={field.label}
                  value={formData?.[field.name] ?? ""}
                  onChange={handleChange}
                  className="border border-[#ccced1] p-2"
                />
              </div>
            )}
          </div>
        ))}
              <div className='relative flex w-full items-center justify-center'>
        {(file || formData.bannerImage) && (
          <Fragment>
            <div
              className='absolute right-4 top-4 z-10 cursor-pointer'
              onClick={() => {
                setFormData({...formData , bannerImage : null})
                setFile(null)
              }}
              role='button'
            >
              <MdOutlineCancel size={40} className='rounded-3xl bg-blue-300' />
            </div>
          </Fragment>
        )}
        <label
          htmlFor='dropzone-file'
          className='flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dashed-border'
        >
          {file || formData.bannerImage ? (
            <img
              src={ file ? URL.createObjectURL(file) : formData.bannerImage }
              alt='Preview' 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              width={1200}
              height={1200}
            />
          ) : (
            <div className='flex flex-col items-center justify-center pb-6 pt-5'>
              <svg
                className='mb-4 h-8 w-8 text-gray-500 dark:text-gray-400'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 16'
              >
                <path
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                />
              </svg>
              <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                <span className='font-semibold'>Click to upload</span> or drag
                and drop
              </p>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
          )}
          <input
            id='dropzone-file'
            type='file'
            className='hidden'
            onChange={handleFileChange}
          />
        </label>
      </div>
        <div className="flex justify-center">
          <button
            onClick={handleSubmit}
            className="btn btn-primary p-2"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? (
              <>{formData?._id ? "UpdatIng..." : "Saving..."} </>
            ) : (
              <> {formData?._id ? "Update" : "Create"} Blog </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateBlogPage;

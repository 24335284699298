import { useState } from 'react';
import {toast} from 'react-toastify';
import { BASE_URL } from '../../../common/consts/Config';
import InternationalModal from './InternationalModal';
import Helper from '../../../common/consts/Helper';

export default function InternationalRegistration(props) {

    const [services, setServices] = useState(props?.location?.state?.service?.services)
    const [data, setData] = useState([]);
    
    function processDate(date) {
        let processedDate = new Date(date);
        return(`${processedDate.getDate()}-${processedDate.getMonth()+1}-${processedDate.getFullYear()}`)
    }

    function setStatus(e, id, index) {
        setServiceState(e, index)
        let obj = {
            serviceId: id,
            serviceStatus: parseInt(e.target.value)
        }
        Helper('changeStatus', 'POST', obj, false)
        .then(response => {
            toast.success(response.msg)
        })
        .catch(error => {
            console.log(error)
        })
    }

    function setServiceState(e, index) {
        let serviceArr = services;
        serviceArr[index].serviceStatus = parseInt(e.target.value);
        setServices([...serviceArr])
    }


    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                        </th>
                                        <th className="text-center">
                                            Email
                                        </th>
                                        <th className="text-center">
                                            Mobile
                                        </th>
                                        <th className="text-center">
                                            Needed Services
                                        </th>
                                        <th className="text-center">
                                            Details
                                        </th>
                                        <th className="text-center">
                                            Trademark Name
                                        </th>
                                        <th className="text-center">
                                            Logo
                                        </th>
                                        <th className="text-center">
                                            Details
                                        </th>
                                        <th className="text-center">
                                            Request Date
                                        </th>
                                        <th className="text-center">
                                            Status
                                        </th>

                                    </thead>
                                    <tbody>
                                        {
                                            services.map((service, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{service?.userDetails?.email}</td>
                                                    <td className="text-center">{service?.userDetails?.mobile}</td>
                                                    <td className="text-center">
                                                        <p>
                                                            Trademark Search {service?.internationalServiceOrdered?.trademarkSearch === "true" ? <button className="btn btn-primary"><i class="fas fa-check"></i></button> : <button className="btn btn-secondary"><i class="fas fa-times"></i></button>}
                                                        </p>
                                                        <p>
                                                            Trademark Application {service?.internationalServiceOrdered?.trademarkApplication === "true" ? <button className="btn btn-primary"><i class="fas fa-check"></i></button> : <button className="btn btn-secondary"><i class="fas fa-times"></i></button>}
                                                        </p>
                                                    </td>
                                                    <td className="text-center">{service?.moreAboutService}</td>
                                                    <td className="text-center">{service?.tradeName ? service?.tradeName : "-"}</td>
                                                    <td className="text-center">{service?.image ? 
                                                    <img className="applicationImg" src={`${BASE_URL}${service?.image}`}/>
                                                    : "-"}</td>
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#recordalModal" onClick={() => setData(service)}>View</button>
                                                    </td>
                                                    <td className="text-center">{processDate(service?.createdAt)}</td>
                                                    <td className="text-center">
                                                        <select className="form-control" onChange={(e) => setStatus(e, service?._id, index)} value={service?.serviceStatus}>
                                                            <option value={0}>Pending</option>
                                                            <option value={1}>Processing</option>
                                                            <option value={2}>Done</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InternationalModal data={data}/>
        </div>
    )
}
import React from "react";
import { useState } from "react";
import { useLocation, Link , useHistory} from "react-router-dom";
import Helper from "../../common/consts/Helper";
import { useEffect, } from "react";



const Contacts = () => {
  const [response, setResponse] = useState([]);
  const { search } = useLocation();
  const history = useHistory()

  const getData = async () => {
    const queryParams = new URLSearchParams(search);
    const p = queryParams.get("p") || 1;
    const l = queryParams.get("l") || 10;
    const offset = (Number(p) - 1) * +Number(l);

    const queryParams2 = new URLSearchParams();
    queryParams2.set("offset", offset.toString());
    queryParams2.set("limit", l.toString());

    const response = await Helper(`contacts?${queryParams2.toString()}`);
    setResponse(response);
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleDeleteClick = async(id)=>{
  //   try {
  //     setResponse((response)=> {
  //       const newResponse = response.contactForms.filter((item) => item._id !== id);
  //       return {
  //         ...response,
  //         contactForms:newResponse
  //       }
  //     })
  //      await Helper(`contacts/${id}`, "DELETE");
  //      toast.success('contact deleted successfully')
      
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }
  return (
    <div className="content">
      <div className="card py-4 px-2 flex w-full justify-between flex-row">
      <h5 className="">Total Contact Forms : {response?.contactForms?.length}</h5>
      {/* <Link to="/blogs/create" className="btn btn-primary"> Add new Blog  </Link> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  {/* <thead className=" text-primary"> */}
                  <thead>
                    <tr className="text-primary">
                      <th className="text-center">S No.</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Message</th>
                      <th className="text-center">Phone</th>
                      {/* <th className="text-center"></th> */}
                    </tr>
                  </thead>
                  {/* </thead> */}
                  <tbody>
                    {response?.contactForms?.map((contact, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{contact.name}</td>
                        <td className="text-center" >{contact?.email}</td>
                        <td className="text-center">{contact?.subject}</td>
                        <td className="text-center">
                          {contact?.message}
                        </td>
                        <td className="text-center">{contact?.phone}</td>
                        {/* <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger ml-2"
                            onClick={() => handleDeleteClick(contact?._id)}
                          >
                            Delete
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

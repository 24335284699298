import React, { useState,useEffect } from 'react';

//constns
import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';
import ChangeForm from './ChangeForm';
import ChangePassword from './ChangePassword';
import LoginForm from './LoginForm';

export default function Login(props) {

    const [changeForm, setChangeForm] = useState(1)
    const [data, setData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        otp: '',
        mobile: '1234567890',
        userType: 1
    });

    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            <div className="sidenav">
                <div className="login-main-text" style={{ marginTop: "40%" }}>
                    <img className="img-fluid" src="/assets/img/logo-white.png" />
                    <h2 className="text-light mt-5">Welcome!!</h2>
                    <p>Login here to access Admin Panel.</p>
                </div>
            </div>
            <div className="main">
                <div className="col-md-6 col-sm-12">
                    <div className="login-form">
                        {
                            renderForm()
                        }
                    </div>
                </div>
            </div>
        </>
    )

    function renderForm() {
        if (changeForm === 1) {
            return (<LoginForm
                handleChange={handleChange}
                data={data}
                isLoading={isLoading}
                login={login}
                setChangeForm={setChangeForm}
            />)
        }
        else if (changeForm === 2) {
            return (
                <ChangeForm handleChange={handleChange} data={data} sendOTP={sendOTP} isLoading={isLoading} />
            )
        }
        else if (changeForm === 3) {
            return (
                <ChangePassword handleChange={handleChange} data={data} changePassword={changePassword} isLoading={isLoading} setChangeForm={setChangeForm} />
            )
        }
    }

    function handleChange(e) {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    function changePassword(e) {
        e.preventDefault();
        if (data.password === data.confirmPassword) {
            setIsLoading(true);
            let body = {
                password: data.password,
                otp: data.otp
            }
            let res = Helper('resetPassword', 'POST', body);
            res.then((response) => {
                if (response?.error) {
                    toast.error(response.error)
                }
                else {
                    toast.success(res.message)
                    setChangeForm(1)
                }
            })
                .catch((error) => toast.error("Something went wrong, Please contact Admin"))
                .finally(() => setIsLoading(false));
        }
        else {
            toast.error("Password does not match with conform password")
        }
    }

    function sendOTP(e) {
        e.preventDefault();
        setIsLoading(true);
        let body = {
            email: data.email
        }
        let res = Helper('forgotPassword', 'POST', body);
        res.then((response) => {
            if (response?.error) {
                toast.error(response.error)
            }
            else {
                toast.success(res.message)
                setChangeForm(3)
            }
        })
            .catch((error) => toast.error("Something went wrong, Please contact Admin"))
            .finally(() => setIsLoading(false));
    }

    function login(e) {
        e.preventDefault();
        setIsLoading(true);
        let body = {
            email: data.email,
            password: data.password,
            userType: 1,
            mobile: '1234567890'
        }
        let res = Helper('signin', 'POST', body);
        res.then((response) => {
            if (response?.error) {
                toast.error(response.error)
            }
            else {
                localStorage.setItem('access_token', response.accessToken);
                localStorage.setItem('currentPage', 'Dashboard');
                props.history.push('/');
            }
        })
            .catch((error) => toast.error("Something went wrong, Please contact Admin"))
            .finally(() => setIsLoading(false));

    }
}

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Helper from '../../consts/Helper';

export default function SideBar() {

    const [serviceNames, setServiceNames] = useState([]);
    const [routes, setRoutes] = useState([]);

    let location = useLocation();

    useEffect(() => {
        Helper('getServiceNames', "POST", {}, false)
            .then(response => {
                setServiceNames(response.serviceNames)
                if(response?.serviceNames && !localStorage.getItem('services')){
                    localStorage.setItem('services', JSON.stringify(response.serviceNames))
                }
            })
            .catch(error => {
                toast.error("Please Login again")
            })
    }, [])

    function checkServiceNew(service){
        let newService = false;

        try{
            const localServices = JSON.parse(localStorage.getItem('services'))
            const indexOfService = localServices.findIndex(e=>e._id===service._id);
            if(indexOfService!==-1){
                if(localServices[indexOfService].services.length!==service.services.length){
                    newService = true;
                }
            }
        }
        catch(error){

        }

        return newService;
    }

    useEffect(() => {
        let routesArr = [];
        let defaultObj = {
            name: 'Dashboard',
            path: '/'
        }
        let BlogsObj ={
            name: 'Blogs',
            path: '/blogs'
        }
        routesArr.push(defaultObj)
        routesArr.push(BlogsObj)
        routesArr.push({
            name:'Contacts',
            path:'/contacts'
        })
        serviceNames?.forEach((serviceName, index) => {
            let obj = {name: '', path: ''}
            if(serviceName.name === "Preliminary Trademark Search") {
                obj = {
                    name: serviceName.name,
                    path: "/search",
                    services: serviceName
                }
            }
            else if(serviceName.name === "INTERNATIONAL TRADEMARK REGISTRATION") {
                obj = {
                    name: serviceName.name,
                    path: "/international",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Registration Of Well-Known Trademark") {
                obj = {
                    name: serviceName.name,
                    path: "/well-known",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Reply to Examination Report") {
                obj = {
                    name: serviceName.name,
                    path: "/office_action",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Registration Of Trademark With Indian Customs") {
                obj = {
                    name: serviceName.name,
                    path: "/indian_customs",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Cancellation Proceedings") {
                obj = {
                    name: serviceName.name,
                    path: "/cancellation",
                    services: serviceName
                }
            }
            else if(serviceName.name === "International Registration") {
                obj = {
                    name: serviceName.name,
                    path: "/international",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Opposition Proceedings") {
                obj = {
                    name: serviceName.name,
                    path: "/opposition",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Trademark Registration in India") {
                obj = {
                    name: serviceName.name,
                    path: "/trademark_registration",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Trademark Registration For Start-Ups") {
                obj = {
                    name: serviceName.name,
                    path: "/start_up",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Recordal Of Address Change") {
                obj = {
                    name: serviceName.name,
                    path: "/address_change",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Recordal Of Change Of Attorney") {
                obj = {
                    name: serviceName.name,
                    path: "/attorney",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Recordal Of Change Of Name") {
                obj = {
                    name: serviceName.name,
                    path: "/name_change",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Recordal Of Change Of Proprietorship") {
                obj = {
                    name: serviceName.name,
                    path: "/proprietorship",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Trademark Renewal") {
                obj = {
                    name: serviceName.name,
                    path: "/renewal",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Assignment Of Trademark") {
                obj = {
                    name: serviceName.name,
                    path: "/assignment",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Licenses") {
                obj = {
                    name: serviceName.name,
                    path: "/licenses",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Trademark Litigation") {
                obj = {
                    name: serviceName.name,
                    path: "/litigation",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Cease & Desist Notices") {
                obj = {
                    name: serviceName.name,
                    path: "/cease",
                    services: serviceName
                }
            }
            else if(serviceName.name === "Anti-Counterfeit Practices") {
                obj = {
                    name: serviceName.name,
                    path: "/anti_counterfeit",
                    services: serviceName
                }
            }
            routesArr.push(obj);
            setRoutes(routesArr);
        });
    }, [serviceNames])

    return (
        <div className="sidebar" data-color="white" data-active-color="danger">
            <div className="logo d-flex align-items-center" style={{height: "100px"}}>
                <a href="#" className="simple-text logo-mini p-0 m-0" style={{width: "85px"}}>
                    <div className="logo-image-small" style={{width: "100px"}}>
                        <img className="img-fluid" src="./assets/img/3d-decode-ip-logo.png"/>
                    </div>
                </a>
                <a href="#" className="simple-text logo-normal">
                    Decode IP
            </a>
            </div>
            <div className="sidebar-wrapper">
                <ul className="nav">
                    {
                        routes.map((route, index) => {
                            return (
                                <li
                                    key={index}
                                    className={location.pathname === route.path ? "active": ""}
                                    onClick={()=>{
                localStorage.setItem('services', JSON.stringify(serviceNames))
                                    }}
                                >
                                    <Link
                                        to={{pathname: `${route.path}`, state: {service: serviceNames[index-1]}}}
                                    >
                                        <p>{route.name} {renderNewTag(route.services)}</p>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
    function renderNewTag(service){
        if(checkServiceNew(service))
            return(
                <span className="text-primary">New</span>
            )
        else return null
    }
}

//<i className={v.icon}></i>

import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Dashboard from '../dashboard';
import Users from '../Users';

//common
import SideBar from '../../common/components/sidebar';
import Navbar from '../../common/components/navbar';
import Requests from '../Requests';
import  AllServices from '../AllServices'
import PreliminarySearch from '../AllServices/PreliminarySearch';
import OffcieAction from '../AllServices/OfficeAction';
import AssignmentTrademark from '../AllServices/AssignmentTrademark'
import Licenses from '../AllServices/Licenses'
import CeaseAndDesist from '../AllServices/CeaseAndDesist';
import Litigation from '../AllServices/Litigation';
import AntiCounterfeit from '../AllServices/Anticounterfeit';
import TrademarkRenewal from '../AllServices/TrademarkRenewal/TrademarkRenewal';
import NameChange from '../AllServices/ChangeName';
import AddressChange from '../AllServices/AddressChange';
import Proprietorship from '../AllServices/Proprietorship';
import Opposition from '../AllServices/Opposition';
import Cancellation from '../AllServices/Cancellation';
import RegistrationIndianCustoms from '../AllServices/RegistrationIndianCustoms';
import InternationalRegistration from '../AllServices/InternationalRegistration';
import Startups from '../AllServices/Startups';
import TrademarkRegistration from '../AllServices/TrademarkRegistration.jsx';
import WellKnownTrademark from '../AllServices/WellKnownTrademark';
import Blogs from '../blogs';
import CreateBlogPage from '../blogs/create';
import Contacts from '../contacts';

export default function Main(props) {
    return (
        <div className="wrapper">
            <SideBar />
            <div className="main-panel">
                <Navbar {...props} />
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/blogs" component={Blogs} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/blogs/create" component={CreateBlogPage} />
                    <Route path="/search" component={PreliminarySearch}/>
                    <Route path="/office_action" component={OffcieAction}/>
                    <Route path="/assignment" component={AssignmentTrademark}/>
                    <Route path="/licenses" component={Licenses}/>
                    <Route path="/cease" component={CeaseAndDesist}/>
                    <Route path="/litigation" component={Litigation}/>
                    <Route path="/anti_counterfeit" component={AntiCounterfeit}/>
                    <Route path="/renewal" component={TrademarkRenewal}/>
                    <Route path="/name_change" component={NameChange}/>
                    <Route path="/address_change" component={AddressChange}/>
                    <Route path="/proprietorship" component={Proprietorship}/>
                    <Route path="/opposition" component={Opposition}/>
                    <Route path="/cancellation" component={Cancellation}/>
                    <Route path="/indian_customs" component={RegistrationIndianCustoms}/>
                    <Route path="/international" component={InternationalRegistration}/>
                    <Route path="/start_up" component={Startups}/>
                    <Route path="/trademark_registration" component={TrademarkRegistration}/>
                    <Route path="/well-known" component={WellKnownTrademark}/>
                </Switch>
                {/* <Footer /> */}
            </div>
        </div>
    )
}

import Spinner from '../../common/components/Spinner';

export default function ChangeForm({handleChange, data, sendOTP, isLoading }) {
    return (
        <form>
            <div className="form-group">
                <label>Email</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={data.username}
                />
            </div>
            <div className="row">
                <button
                    type="submit"
                    className="btn btn-black"
                    disabled={isLoading}
                    onClick={sendOTP}
                >
                    {
                        isLoading ? <Spinner /> : 'Send OTP'
                    }
                </button>
            </div>
        </form>
    )
}
export default function InternationalModal({ data }) {
    return (
        <div class="modal fade" id="recordalModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Application</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive mb-5">
                                                <table className="table">
                                                    <tbody>
                                                        <tr className="text-primary">
                                                            <th className="text-center">
                                                                Email
                                                        </th>
                                                            <th className="text-center">
                                                                Countries
                                                        </th>
                                                            <th className="text-center">
                                                                Classes
                                                        </th>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">{data?.userDetails?.email}</td>
                                                            <td className="text-center">
                                                                {
                                                                    data?.internationalCountry?.map((country, index) => (
                                                                        <p key={index}>{country}</p>
                                                                    ))
                                                                }</td>
                                                            <td className="text-center">
                                                                {
                                                                    data?.internationalClass?.map((cls, index) => (
                                                                        <p>{cls.classId}</p>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <label for="exampleFormControlTextarea1" class="form-label form-heading">Details</label>
                                            <textarea class="form-control p-3" id="exampleFormControlTextarea1" rows="3" value={data?.moreAboutService}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//toast contaienr and css
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

//screens
import Main from './components/main';
import Login from './components/login';

//atnd css
import 'antd/dist/antd.css';
import './App.css'

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" render={
            (props) =>
              localStorage.getItem('access_token')
                 ? <Main {...props} />
                : <Login {...props} />
          }
          />
        </Switch>
      </Router>
      <ToastContainer />
    </div >
  );
}

export default App;

import {
    BASE_URL
} from './Config';

// import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';


const Helper = (url, method, data, isFormData = false) => {
    return (
        fetch(BASE_URL + url, {
            method: method,
            body: isFormData ? (data) : (JSON.stringify(data)),
            headers: getHeaders(isFormData)
        }).then((response) => {
            if (response.status === 401) {
                toast.error("Please login")
                window.location.reload()
                localStorage.removeItem('access_token');
            }

            return response.json()
        }).catch((error) => {
          console.log(error)
        })
    )
}

const getHeaders = (isFormData) => {
    if (isFormData) {
        return {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
    } else {
        return {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json'
        }
    }
}

export default Helper;

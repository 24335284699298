import { Input } from 'antd';
import Helper from '../../common/consts/Helper'
import {useEffect ,useState} from 'react'
const { Search } = Input;
// import Spinner from '../../common/components/Spinner'

export default function Requests() {
  const[userServiceList,setUserServiceList] = useState()
  const[isLoading,setIsLoading]  = useState(false)
  const[showPayment,setShowPayment] = useState(false)
  const[showEnquiry,setShowEnquiry] = useState(false)
  const[userServiceListCopy,setUserServiceListCopy] = useState([])


  useEffect(()=>{
    setIsLoading(true)
    Helper('servicesAdmin',"POST",'',false)
    .then((res)=>{
      setUserServiceList(res.services)
      setUserServiceListCopy(res.services)
    })
    .catch((err)=>console.log(err,"fsadf"))
    .finally(()=>setIsLoading(false))

  },[])

  useEffect(()=>{
    showEnqeryOrPayment()
  },[showPayment,showEnquiry])

    function showEnqeryOrPayment(){
        let filterData = []
        if(showPayment && showEnquiry){
          setUserServiceList(userServiceListCopy)
           return
      }else if(showPayment){
            userServiceListCopy.map((service,indx)=>{
              if(service.isPayment === 1){
                filterData.push(service)
            }
          })
        }else if(showEnquiry){
          userServiceListCopy.map((service,indx)=>{
          if(service.isPayment === 0){
              filterData.push(service)
          }
        })
      }
        setUserServiceList(filterData)
    }

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        {/* <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> View Products</h4>
                            <div className="update">
                                <Link to="/add_product">
                                    <button type="button" className="btn btn-primary">Add Product</button>
                                </Link>
                            </div>
                        </div> */}
                        <div className="card-header">
                            <Search
                                placeholder="input search text"
                                allowClear
                                enterButton="Search"
                                size="large"
                                // onSearch={searchProduct}
                            />
                        </div>
                          <div className="row py-4">
                          <div className="col-3 offset-7">
                          <span className=" d-flex align-items-center justify-content-end">
                            <input
                                className="mr-2"
                                type="checkbox"
                                id="showPayment"
                                name="showPayment"
                                onClick ={()=>setShowEnquiry(!showEnquiry)}
                                defaultChecked={showEnquiry}
                                />
                            Enquiry
                          </span>
                          </div>
                          <div className="col-2">
                          <span className=" d-flex align-items-center justify-content-center pr-4">
                            <input
                                className="mr-2"
                                type="checkbox"
                                id="showPayment"
                                name="showPayment"
                                onClick ={()=>setShowPayment(!showPayment)}
                                defaultChecked={showPayment}
                                />
                            Payment(INR)
                          </span>
                          </div>
                          </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                        </th>
                                        <th className="text-center">
                                            Service Name
                                        </th>

                                        <th className="text-center">
                                            User Email
                                        </th>
                                        <th className="text-center">
                                            User Contact
                                        </th>
                                        <th className="text-center">
                                        <span className=" d-flex align-items-center justify-content-center">
                                          Enquiry
                                        </span>
                                        </th>
                                        <th className="text-center">
                                        <span className=" d-flex align-items-center justify-content-center">
                                          Payment(INR)
                                        </span>
                                        </th>
                                        <th className="text-center">
                                            Status
                                        </th>
                                        <th className="text-center">
                                            View Details
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                          userServiceList?.length > 0  ?(
                                              userServiceList?.map((service,indx)=>
                                              <tr key= {service._id}>
                                                  <td className="text-center">{indx + 1}</td>
                                                  <td className="text-center">{service?.serviceName}</td>
                                                  <td className="text-center">{service?.userDetails?.email}</td>
                                                  <td className="text-center">{service?.userDetails?.mobile}</td>
                                                  <td className="text-center">
                                                    {service.isPayment === 1 ? "No" : "Yes"}
                                                  </td>
                                                  <td className="text-center">
                                                      {service?.isPayment === 1 ? (
                                                        <>
                                                          {(+service?.govtFees + +service?.professionalFee) + ((+service?.govtFees + +service?.professionalFee) *14/100)}
                                                        </>
                                                      ) : "_"}

                                                  </td>
                                                  <td className="text-center">
                                                  <select class="custom-select">
                                                    <option value="Accept">
                                                      {service.serviceStatus === 1 ? "Accept" : "Pending"}
                                                    </option>
                                                    <option value="Pending">
                                                      {service.serviceStatus === 0 ? "Accept" : "Pending"}
                                                    </option>
                                                  </select>
                                                  </td>
                                              </tr>
                                            )
                                          ) : <tr className="text-center">No service Found</tr>
                                        }

                                        {
                                            // isLoading && (
                                            //     <tr>
                                            //         <td colSpan={5} className="text-center m-3"><Spinner /></td>
                                            //     </tr>
                                            // )
                                        }
                                        {
                                            // products.map((product, index) => {
                                            //     return (
                                            //         <tr key={index}>
                                            //             <td className="text-center">
                                            //                 {index + 1}
                                            //             </td>
                                            //             <td className="text-uppercase text-bold" style={{ maxWidth: '130px' }}>
                                            //                 <img
                                            //                     src={`${BASE_URL}/${product.image1}`}
                                            //                 />
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm" style={{ maxWidth: '130px' }}>
                                            //                 {product.productName}
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm" style={{ maxWidth: '130px' }}>
                                            //                 {product?.subcategoryId?.subcategoryName}
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm">
                                            //                <b>₹{product.price}</b>
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.sku}
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.discount}%
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.size.join(', ')}
                                            //             </td>
                                            //             <td className="text-center">
                                            //                 <Switch
                                            //                     loading={isToggling.isToggling && isToggling.isTogglingIndex === index}
                                            //                     onChange={(checked) => { toggleProductStatus(checked, index, product) }}
                                            //                     checked={product.isActive === 1 ? true : false}
                                            //                 />
                                            //             </td>
                                            //             <td className="text-center">
                                            //                 <button
                                            //                     type="button"
                                            //                     className="btn btn-warning"
                                            //                     onClick={() => {
                                            //                         props.history.push('/edit_product', { product: product, edit: true })
                                            //                     }}
                                            //                     disabled={isToggling.isToggling && isToggling.isTogglingIndex === index}
                                            //                 >
                                            //                     {
                                            //                         (isToggling.isToggling && isToggling.isTogglingIndex === index) ?
                                            //                             <Spinner /> : (
                                            //                                 <i className="fas fa-edit"></i>
                                            //                             )
                                            //                     }
                                            //                 </button>
                                            //             </td>
                                            //         </tr>
                                            //     )
                                            // })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import {useEffect ,useState} from 'react'
import { Input } from 'antd';
import Helper from '../../common/consts/Helper'
const { Search } = Input;


export default function Users() {
  const[users,setUsers] = useState()

  useEffect(()=>{
    Helper('user',"POST",{},false)
    .then((res)=> setUsers(res.result))
    .catch((err)=>console.log(err,"fsadf"))

  },[])


    return(
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        {/* <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> View Products</h4>
                            <div className="update">
                                <Link to="/add_product">
                                    <button type="button" className="btn btn-primary">Add Product</button>
                                </Link>
                            </div>
                        </div> */}
                        <div className="card-header">
                            <Search
                                placeholder="input search text"
                                allowClear
                                enterButton="Search"
                                size="large"
                                // onSearch={searchProduct}
                            />
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                        </th>
                                        <th className="text-center">
                                            Mobile
                                        </th>
                                        <th className="text-center">
                                            Email
                                        </th>
                                    </thead>
                                    <tbody>
                                      {
                                        users?.length > 0 ? (
                                          users.map((user,indx)=>
                                          <tr key = {user?.mobile}>
                                              <td className="text-center">{indx + 1}</td>
                                              <td className="text-center">{user.mobile}</td>
                                              <td className="text-center">{user.email}</td>
                                          </tr>
                                          )
                                        ) : <span>No user Found</span>
                                      }

                                        {
                                            // isLoading && (
                                            //     <tr>
                                            //         <td colSpan={5} className="text-center m-3"><Spinner /></td>
                                            //     </tr>
                                            // )
                                        }
                                        {
                                            // products.map((product, index) => {
                                            //     return (
                                            //         <tr key={index}>
                                            //             <td className="text-center">
                                            //                 {index + 1}
                                            //             </td>
                                            //             <td className="text-uppercase text-bold" style={{ maxWidth: '130px' }}>
                                            //                 <img
                                            //                     src={`${BASE_URL}/${product.image1}`}
                                            //                 />
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm" style={{ maxWidth: '130px' }}>
                                            //                 {product.productName}
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm" style={{ maxWidth: '130px' }}>
                                            //                 {product?.subcategoryId?.subcategoryName}
                                            //             </td>
                                            //             <td className="text-capitalize fa-sm">
                                            //                <b>₹{product.price}</b>
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.sku}
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.discount}%
                                            //             </td>
                                            //             <td className="text-uppercase fa-sm">
                                            //                 {product.size.join(', ')}
                                            //             </td>
                                            //             <td className="text-center">
                                            //                 <Switch
                                            //                     loading={isToggling.isToggling && isToggling.isTogglingIndex === index}
                                            //                     onChange={(checked) => { toggleProductStatus(checked, index, product) }}
                                            //                     checked={product.isActive === 1 ? true : false}
                                            //                 />
                                            //             </td>
                                            //             <td className="text-center">
                                            //                 <button
                                            //                     type="button"
                                            //                     className="btn btn-warning"
                                            //                     onClick={() => {
                                            //                         props.history.push('/edit_product', { product: product, edit: true })
                                            //                     }}
                                            //                     disabled={isToggling.isToggling && isToggling.isTogglingIndex === index}
                                            //                 >
                                            //                     {
                                            //                         (isToggling.isToggling && isToggling.isTogglingIndex === index) ?
                                            //                             <Spinner /> : (
                                            //                                 <i className="fas fa-edit"></i>
                                            //                             )
                                            //                     }
                                            //                 </button>
                                            //             </td>
                                            //         </tr>
                                            //     )
                                            // })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

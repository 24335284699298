import Pagination from "./pagination";
import React from "react";
import TableBody from "./tableBody";
import SelectLimit from "./selectbox";
import { useState } from "react";
import { useLocation, Link , useHistory} from "react-router-dom";
import Helper from "../../common/consts/Helper";
import { useEffect, } from "react";
import {toast} from 'react-toastify'

function truncateTextToWordLimit(text, wordLimit) {
  // Split the text into words
  const words = text.split(/\s+/);
  
  // Check if the text exceeds the word limit
  if (words?.length <= wordLimit) {
    return text;
  }
  
  // Truncate the words to the specified word limit
  const truncatedWords = words?.slice?.(0, wordLimit);
  
  // Join the truncated words back into a single string
  const truncatedText = truncatedWords?.join?.(' ');
  
  return truncatedText + '...'; // Adding ellipsis to indicate truncation
}


const Blogs = () => {
  const [response, setResponse] = useState([]);
  const { pathname, search } = useLocation();
  const history = useHistory()

  const getData = async () => {
    const queryParams = new URLSearchParams(search);
    const p = queryParams.get("p") || 1;
    const l = queryParams.get("l") || 10;
    const offset = (Number(p) - 1) * +Number(l);

    const queryParams2 = new URLSearchParams();
    queryParams2.set("offset", offset.toString());
    queryParams2.set("limit", l.toString());

    const response = await Helper(`blogs?${queryParams2.toString()}`);
    setResponse(response);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteClick = async(id)=>{
    try {
      setResponse((response)=> {
        const newResponse = response.blogs.filter((item) => item._id !== id);
        return {
          ...response,
          blogs:newResponse
        }
      })
       await Helper(`blogs/${id}`, "DELETE");
       toast.success('blogs deleted successfully')
      
    } catch (error) {
      toast.error(error.message)
    }

  }
console.log(response ,"response")
  return (
    <div className="content">
      <div className="card py-4 px-2 flex w-full justify-between flex-row">
      <h5 className="">Total Blogs : {response?.totalCount}</h5>
      <Link to="/blogs/create" className="btn btn-primary"> Add new Blog  </Link>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  {/* <thead className=" text-primary"> */}
                  <thead>
                    <tr className="text-primary">
                      <th className="text-center">S No.</th>
                      <th className="text-center">title</th>
                      <th className="text-center">Description</th>
                      <th className="text-center">Meta Title</th>
                      <th className="text-center">Meta Description</th>
                      <th className="text-center">keywords</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  {/* </thead> */}
                  <tbody>
                    {response?.blogs?.map((service, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{service.title}</td>
                        <td className="text-center"  style={{maxWidth:'500px'}}>{truncateTextToWordLimit(service?.description , 125)}</td>
                        <td className="text-center">{service?.metaTitle}</td>
                        <td className="text-center">
                          {service?.metaDescription}
                        </td>
                        <td className="text-center">{service?.keywords}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => history.push(`/blogs/create?id=${service?._id}`)}
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger ml-2"
                            onClick={() => handleDeleteClick(service?._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
